<template>
  <div class="login">
    <loginHeader></loginHeader>
    <div class="login-body" :class="clientWidth > 750 ? 'flex3' : 'flex1'">
      <div class="login-content" :class="clientWidth > 750 ? 'flex0' : ''">
        <div class="login-detail">
          <div class="login-detail-title">
            <div v-for="(item, index) in loginTitles" @click="tabForm(index)" :key="index"
              class="login-detail-title-item" :class="index == tabNewIndex ? 'login-detail-title-item-active' : ''"> {{
      item }} </div>
          </div>
          <div class="login-detail-form">
            <!-- 验证码登录 -->
            <el-form class="loginForm codeForm" v-if="tabNewIndex == 0" :model="form0" ref="Forms0" :rules="rules0">
              <el-form-item prop="way" class="way_input">
                <el-radio-group v-model="form0.way" @change="codetypechange">
                  <el-radio label="phone">手机号验证</el-radio>
                  <el-radio label="email">邮箱验证</el-radio>
                </el-radio-group>
                <!-- <p class="notice">*非中国大陆手机号请使用邮箱验证方式</p> -->
              </el-form-item>
              <el-form-item prop="phone" v-if="form0.way == 'phone'">
                <el-input v-model="form0.phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
              <el-form-item prop="email" v-else>
                <el-input v-model="form0.email" placeholder="请输入您的邮箱地址"></el-input>
              </el-form-item>
              <el-form-item prop="code" :error="errorMsg">
                <div class="flex2">
                  <el-input v-model="form0.code" placeholder="请输入验证码" style="margin-right: 5px"
                    maxlength="6"></el-input>
                  <el-button class="getCode" v-if="codeShow" @click="getPhoneCode">获取验证码</el-button>
                  <el-button class="getCode" v-else disabled>{{ count }} 秒后重试</el-button>
                </div>
              </el-form-item>
              <el-form-item class="loginStateBox">
                <el-checkbox v-model="form0.loginState">保持登录</el-checkbox>
              </el-form-item>

              <el-form-item>
                <el-button type="info" size="medium" :loading="loading" @click="submitForm0('Forms0')"
                  class="loginBtn">登 录</el-button>
              </el-form-item>
            </el-form>

            <!-- 账号密码登录 -->
            <el-form class="loginForm" v-if="tabNewIndex == 1" :model="form1" ref="Forms1" :rules="rules1">
              <el-form-item prop="account">
                <el-input v-model="form1.account" placeholder="请输入账号（手机号或邮箱）"></el-input>
              </el-form-item>
              <el-form-item prop="password" :error="errorMsg">
                <div style="display: flex; width: 100%">
                  <el-input type="password" v-model="form1.password" placeholder="请输入密码"></el-input>
                </div>
              </el-form-item>
              <el-form-item class="loginStateBox">
                <el-checkbox v-model="form1.loginState">保持登录</el-checkbox>
              </el-form-item>
              <el-form-item>
                <el-button type="info" size="medium" :loading="loading" @click="submitForm1('Forms1')"
                  class="loginBtn">登 录</el-button>
              </el-form-item>
            </el-form>

            <!-- 微信扫码登录 -->
            <div class="loginWeChat" v-if="tabNewIndex == 2">
              <div style="height: 40px;line-height: 40px">
                <p v-if="!sweepCode" class="tc">如果您还没有绑定，请先扫码绑定，然后再进行登录</p>
              </div>
              <van-loading v-if="wxLoading" type="spinner" />
              <div class="weChatCode">
                <div class="weChatCode-img" :class="!wxLoading ? 'weChatCode-imgs' : ''">
                  <img v-if="!wxLoading" :src="weChatCode.fileurl" alt="">
                </div>
                <div v-if="sweepCode" class="weChatCode-success">
                  <div v-if="!codeExpired" class="tc">
                    <p style="margin-bottom: 35px">扫码成功！</p>
                    <i class="el-icon-success" style=""></i>
                  </div>
                  <div v-else class="tc">
                    <p style="margin-bottom: 35px;white-space: nowrap;margin-left: -20px;">二维码已过期，请刷新重试！</p>
                    <i class="el-icon-warning" style="color: #430f5c;font-size: 75px"></i>
                  </div>
                </div>
                <el-checkbox v-model="loginState" class="loginStateBox">保持登录</el-checkbox>
                <p class="reload link tc mt15" @click="reload">刷新</p>
              </div>
            </div>
            <p class="tc">
              <span>没有账户？ </span>
              <span class="CancelHome link" @click="goRegister">立即创建</span>
              <span class="CancelHome link" @click="goFindBack">找回密码</span>
              <span class="CancelHome link" @click="goHome">返回首页</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <login-alert></login-alert>
    <Footers></Footers>
  </div>
</template>

<script>
import LoginAlert from "@/components/LoginAlert/index.vue";

import loginHeader from "@/components/loginHeader.vue";
import { dataState } from "@/api/all.js";
import { login1, login0, GetWMImage, GetWMImageState, ValidateIsAuthorization, ValidateRegister } from "@/api/user.js";
import { getCode } from "@/api/register.js";
import { debounce, Login_GetInfoDetails } from "@/utils/common";
import { ApiToken } from '@/utils/ApiToken'
import Footers from '@/components/footer.vue';

import { mapState } from 'vuex'

export default {
  name: "login",
  data() {
    return {
      loginTitles: this.$store.state.clientWidth < 750 ? ["验证码登录", "账号密码登录"] : ["验证码登录", "账号密码登录", "微信扫码登录"],
      wxLoading: true,                                   // !微信二维码loading
      errorMsg: "",                                      // !错误信息
      codeShow: true,                                    // !验证码状态
      count: "",                                         // !验证码倒计时
      timer: "",                                         // !验证码状态
      loading: false,                                    // !loading
      tabOldIndex: 0,                                    // !切换前的登录方式
      tabNewIndex: 0,                                    // !切换后的登录方式
      weChatCode: {
        ewm_Id: '',
        fileurl: '',
      },                                                 // !二维码
      sweepCode: false,                                  // !是否扫码授权
      codeExpired: false,                                // !二维码是否过期
      timer: null,                                       // !二维码定时器
      uuid: '',                                           // !微信注册id
      loginState: false,
      form0: {
        way: 'phone',
        email: '',
        phone: "",
        code: "",
        loginState: false
      },
      form1: {
        account: "",
        password: "",
        loginState: false
      },
      rules0: {
        email: [
          { required: true, message: "请输入您的邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入您的手机号", trigger: "blur" },
          // {
          //   pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
          //   message: "请输入正确的手机号",
          //   trigger: "blur",
          // },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      rules1: {
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    // 数据统计
    const data = {
      visitType: 1,
      gid: sessionStorage.getItem("gid") ? sessionStorage.getItem("gid") : "",
      url: window.location.href,
      pageUrl: "login",
      relationId: "",
    };
    dataState(data).then((res) => {
      sessionStorage.setItem("gid", res.data);
    });
  },
  methods: {
    codetypechange(val) {
      console.log(val)
    },
    // !登录方式切换
    tabForm(index) {
      this.tabNewIndex = index;
      if (this.tabNewIndex == 0 && this.tabOldIndex == 1) {
        this.form1.loginState=false;
        this.resetForm('Forms1') // 重置表单
      } else if (this.tabNewIndex == 1 && this.tabOldIndex == 0) {
        this.form0.loginState=false;
        this.resetForm('Forms0') // 重置表单
      }
      this.loginState=false;
      if (index == 2) {
        this.getWeChatCode(); // 获取二维码
      } else {
        clearInterval(this.timer);
        this.timer = null;
      }
      this.tabOldIndex = index;
    },
    // !验证码登录
    submitForm0(Form0) {
      this.$refs[Form0].validate((valid) => {
        if (valid) {
          this.loading = true;
          login0(this.form0).then((res) => {
            console.log(this.form0)
            if (res.status == 1) {
              this.$store.commit("$_setStorage", res.data.token)
              this.$toast.success({ message: "登录成功！", duration: 1000 });
              setTimeout(() => {
                Login_GetInfoDetails(this.$route)
              }, 800);
            } else {
              this.loading = false;
              this.errorMsg = null;
              this.$toast({ message: res.message, duration: 2000 });
              this.$nextTick(() => {
                this.errorMsg = res.message;
              });
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // !账号密码登录
    submitForm1(Form1) {
      this.$refs[Form1].validate((valid) => {
        if (valid) {
          this.loading = true;
          login1(this.form1).then((res) => {
            if (res.status == 1) {
              this.$store.commit("$_setStorage", res.data.token)
              this.$toast.success("登录成功！")
              setTimeout(() => {
                Login_GetInfoDetails(this.$route)
              }, 800);
            } else {
              this.loading = false;
              this.errorMsg = null;
              this.$toast({ message: res.message, duration: 2000 });
              this.$nextTick(() => {
                this.errorMsg = res.message;
              });
            }
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
    // !重置表单
    resetForm(form) {
      this.$refs[form].resetFields();
    },
    // !点击获取验证码
    getPhoneCode() {
      const phone = this.form0.phone;
      const email = this.form0.email;
      if (this.form0.way == 'phone') {
        if (!phone) {
          this.$toast({ message: "请先输入您的手机号！" });
          return false;
          // } else if (!validator(phone,1)) {
          //   this.$toast({ message: "请输入正确的手机号！" });
          //   return false;
        } else {
          debounce(() => {
            getCode({ phone: phone, type: 1, way: 'phone' }).then((res) => {
              if (res.status == 1) {
                this.$toast({ message: "发送成功！", duration: 1000 });
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.codeShow = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.codeShow = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
              } else {
                this.$toast({ message: res.message, duration: 2000 });
              }
            });
          }, 1000);
        }
      } else if (this.form0.way == 'email') {
        if (!email) {
          this.$toast({ message: "请先输入您的邮箱地址！" });
          return false;
        } else if (!validator(email)) {
          this.$toast({ message: "请输入正确的邮箱地址！" });
          return false;
        } else {
          debounce(() => {
            getCode({ email: email, type: 1, way: 'email' }).then((res) => {
              if (res.status == 1) {
                this.$toast({ message: "发送成功！", duration: 1000 });
                const TIME_COUNT = 60;
                if (!this.timer) {
                  this.count = TIME_COUNT;
                  this.codeShow = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= TIME_COUNT) {
                      this.count--;
                    } else {
                      this.codeShow = true;
                      clearInterval(this.timer);
                      this.timer = null;
                    }
                  }, 1000);
                }
              } else {
                this.$toast({ message: res.message, duration: 2000 });
              }
            });
          }, 1000);
        }
      }

      // 正则验证格式
      function validator(value) {
        var reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        return RegExp(reg).test(value);
      }
    },
    // !获取二维码
    getWeChatCode() {
      this.wxLoading = true;
      const urls = encodeURIComponent(ApiToken.shareUrl + "Loginwechat")
      GetWMImage(urls).then(res => {
        this.wxLoading = false;
        if (res.status = 1) {
          this.weChatCode = res.data;
          this.timer = setInterval(() => {
            this.GetWMImageStates(res.data.ewm_Id); //监听二维码状态
          }, 1200);
        } else {
          this.$toast(res.message);
        }
      })
    },
    // !刷新二维码
    reload() {
      this.sweepCode = false;
      clearInterval(this.timer);
      this.timer = null;
      this.getWeChatCode();
    },
    // !监听是否扫码
    GetWMImageStates(id) {
      GetWMImageState(id).then(res => {
        if (res.status == 1) {
          if (res.data.state == 0) {
            this.sweepCode = false;
            this.codeExpired = false;
          } else if (res.data.state == 1) {
            this.sweepCode = true;
            this.codeExpired = false;
            this.ValidateIsAuthorizations(id)
          } else if (res.data.state == 2) {
            this.sweepCode = true;
            this.codeExpired = true;
          }
        } else {
          this.$toast(res.message);
        }
      })
    },
    // !监听是否微信授权
    ValidateIsAuthorizations(id) {
      const Id = { "wmId": id };
      ValidateIsAuthorization(Id).then(res => {
        if (res.status == 1) {
          if (res.data.state == 0) {
            //未授权
          } else if (res.data.state == 1) {
            //已授权
            this.ValidateRegisters(res.data.uuId);
          }
        }
      })
    },
    // !验证是否绑定微信
    ValidateRegisters(uuId) {
      this.uuid = uuId;
      const uuid = { "uuid": uuId,"loginState":this.loginState };
      ValidateRegister(uuid).then(res => {
        if (res.status == 1) {
          this.$store.commit("$_setStorage", res.data)
          setTimeout(() => {
            Login_GetInfoDetails(this.$route)
          }, 800);
        } else if (res.status == -300) {
          clearInterval(this.timer);
          this.timer = null;
          this.$router.push("/LoginWXgo?uuid=" + this.uuid);
        } else if (res.status == -200) {
          clearInterval(this.timer);
          this.timer = null;
          this.$toast(res.message);
          setTimeout(() => {
            if (this.$route.query.from) {
              this.$router.push("/LoginWXgo?uuid=" + this.uuid + '&from=' + this.$route.query.from)
            } else {
              this.$router.push("/LoginWXgo?uuid=" + this.uuid);
            }
          }, 500);
        } else if (res.status == -201) {
          this.$toast(res.message);
          setTimeout(() => {
            this.reload();
          }, 500);
        } else {
          this.$toast(res.message);
        }
      })
    },
    // !去注册
    goRegister() {
      if (this.$route.query.from) {
        this.$router.push('/Register?redirectUrl=' + this.$route.query.redirectUrl + '&from=' + this.$route.query.from)
      } else if (this.channel === 1 || this.$route.query.from) {
        this.$router.push('/Register?redirectUrl=' + this.$route.query.redirectUrl)
      } else {
        this.$router.push('/Register?redirectUrl=' + this.$route.query.redirectUrl)
      }
    },
    // !去首页
    goHome() {
      if (this.$route.query.from || (this.channel === 1 || this.$route.query.from)) {
        this.$router.push('/Home/Live')
      } else {
        this.$router.push('/')
      }
    },
    // !找回密码
    goFindBack() {
      if (this.$route.query.from) {
        this.$router.push('/FindBack?redirectUrl=' + this.$route.query.redirectUrl + '&from=' + this.$route.query.from)
      } else if (this.channel === 1) {
        this.$router.push('/FindBack?redirectUrl=' + this.$route.query.redirectUrl)
      } else {
        this.$router.push('/FindBack')
      }
    },
  },
  computed: {
    ...mapState(['clientWidth', 'channel'])
  },
  components: {
    loginHeader,
    LoginAlert,
    Footers
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
  }
};
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

@media (min-width: 750px) {


  ::v-deep .el-checkbox.loginStateBox{
    margin-top: 20px;
  }
  ::v-deep .loginStateBox {
    margin-bottom: 0px;
    .el-checkbox__input{
      margin-bottom: 1px;
    }
  }

  .login {
    min-height: 500px;
    height: 100vh;
    background: #f7f7f7;

    .weChatCode {
      width: 130px;
      margin: 0 auto;
      padding: 0px 0px 17px;
      position: relative;
    }

    .weChatCode-img {
      width: 130px;
      height: 130px;
    }

    .weChatCode-imgs {
      box-shadow: 0 0 10px #ccc;
    }

    .weChatCode-img img {
      width: 100%;
    }

    .weChatCode-success {
      width: 130px;
      height: 155px;
      position: absolute;
      top: -30px;
      background: rgba(255, 255, 255, 0.9);
      padding-bottom: 20px
    }

    .weChatCode-success i {
      color: #430f5c;
      font-size: 75px;
    }

    .weChatCode-success p {
      margin-bottom: 35px;
      white-space: nowrap;
    }

    .login-body {
      width: 100%;
      // height: calc(100% - 83px); 
      height: calc(100% - 189px);
      position: relative;
      background: #f7f7f7;

      //  background: url('~@/assets/PC/header/login-bg.png') no-repeat; background-size: cover;
      .login-content {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto
      }

      .login-logo img {
        width: 506px
      }

      .login-detail {
        width: auto;
        min-height: 376px;
        box-shadow: 0 0 5px #ccc;
        background: #fff;

        .login-detail-title {
          height: 78px;
          display: flex;

          .login-detail-title-item {
            font-size: 24px;
            padding: 0 20px;
            font-weight: 400;
            line-height: 78px;
            text-align: center;
            border: 1px #f7f7f7 solid;
            cursor: pointer;
            background: #f7f7f7;
          }

          .login-detail-title-item-active {
            background: #fff;
            border-bottom: 1px #fff solid;
            color: #6b18a8;
          }
        }

        .login-detail-form {
          height: calc(100% - 78px);
          background: #fff;
          padding: 25px 0;

          .loginForm {
            width: 280px;
            margin: 0 auto;

            &.codeForm {
              .el-form-item {
                &.way_input {
                  margin: 0 0 10px;

                  .notice {
                    font-size: 13px;
                    color: red;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .login {
      .el-tabs__item {
        width: 50%;
        box-sizing: border-box;
      }

      .login-verification {
        padding-top: 30px;
        width: 280px;
        margin: 0 auto;
      }

      .ph-login {
        background: #6b18a8;
        width: 100%;
        color: #fff;
      }
    }
  }
}

@media (max-width: 750px) {
  * {
    font-size: vw(24);
  }

  ::v-deep .loginStateBox {
    margin-bottom: 0px;
    .el-checkbox__input{
      margin-bottom: 1px;
    }
  }

  .login {
    min-height: 100vh;
    background: #f7f7f7;

    .login-body {
      width: 100%;
      // min-height: calc(100vh - 16vw); 
      min-height: calc(100vh - 35vw);
      background: #f7f7f7;
    }

    .login-logo {
      text-align: center;
      margin: vw(60) 0;
      overflow: hidden;
    }

    .login-logo img {
      width: vw(321)
    }

    .login-detail {
      width: vw(670);
      min-height: vw(590);
      margin: 0 auto;
      box-shadow: 0 0 5px #ccc;
      background: #fff;
    }

    .login-detail-title {
      height: vw(80);
      display: flex;

      .login-detail-title-item {
        width: vw(335);
        font-size: vw(29);
        font-weight: 400;
        line-height: vw(80);
        text-align: center;
        border: 1px #f7f7f7 solid;
        cursor: pointer;
        background: #f7f7f7
      }

      .login-detail-title-item-active {
        background: #fff;
        border-bottom: 1px #fff solid;
        color: #6b18a8;
      }
    }

    .login-detail-form {
      height: calc(100% - 10.6667vw);
      padding: vw(38) 0;
      background: #fff;

      .loginForm {
        width: vw(558);
        margin: 0 auto;

        &.codeForm {
          .el-form-item {
            &.way_input {
              margin: 0 0 8px;

              .notice {
                font-size: 12px;
                color: red;
                line-height: 20px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style>
.loginWeChat {
  position: relative;
  /*height: 216px;*/
}

.loginWeChat .van-loading {
  text-align: center;
  width: 100%;
  height: 216px;
  line-height: 216px;
  position: absolute;
  top: 0;
}
</style>