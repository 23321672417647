<template>
  <div class="LoginAlert">
    <div v-if="loginAlertState" class="shade flex0">
      <div class="shade-section tc">
        <div  class="shade-section-title">邀你来战！<br/> AVEVA E3D 剑法秘籍</div>
        <div class="shade-section-btn">
          <button type="button" class="xy-btn" @click="subscribe">立即开启</button>
        </div>
        <i class="el-icon-circle-close close-alert co-fff cur" @click="closeAlert"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'LoginAlert',
  data() {
    return {}
  },
  computed: {
    ...mapState(['loginAlertState', 'Infos'])
  },
  methods: {
    // !关闭弹窗
    closeAlert() {
      this.$store.commit('loginAlertState', false)
      if(this.$route.query.redirectUrl) {
        this.$router.push(this.$route.query.redirectUrl)
      } else if (this.Infos.type == 1) {
        this.$router.push('/Home/Live')
      } else {
        this.$router.push('/')
      }
    },
    // !去观看直播
    subscribe() {
      this.$router.push('/Home/E3DVideo')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "./index.scss"
</style>